.fonts {
  @font-face {
    font-family: 'DancingScript-Regular';
    src: url('./fonts/DancingScript-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GreatVibes-Regular';
    src: url('./fonts/GreatVibes-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'JosefinSans-Thin';
    src: url('./fonts/JosefinSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'JosefinSans-Light';
    src: url('./fonts/JosefinSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'JosefinSans-Regular';
    src: url('./fonts/JosefinSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'JosefinSans-Medium';
    src: url('./fonts/JosefinSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'JosefinSans-SemiBold';
    src: url('./fonts/JosefinSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'JosefinSans-Bold';
    src: url('./fonts/JosefinSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lobster-Regular';
    src: url('./fonts/Lobster-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pacifico-Regular';
    src: url('./fonts/Pacifico-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'AmaticSC-Bold';
    src: url('./fonts/AmaticSC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'AmaticSC-Regular';
    src: url('./fonts/AmaticSC-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}
