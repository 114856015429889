.login-page {
  height: 100vh;

  &__card-container {
    width: 350px;

    .card {
      background-color: #ffffff;
      height: 180px;
    }

    .login-button {
      background: #b9885a;
    }
  }
}