.navbar-custom {
  &__brand {
    font-size: 1.5rem;
  }

  &__links-container {
    //display: flex;
    //justify-content: center;
    //align-items: center;

    .nav-link {
      font-size: 1.2rem;
      font-family: 'AmaticSC-Regular';
      margin-top: 3px;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        text-decoration: underline;
      }
    }
  }
}